import { useUserStore } from '@/store/user';
import { toast } from 'sonner';
import { CheckCircleIcon, SquareArrowOutUpRight, X, XCircle } from 'lucide-react';
import Image from 'next/image';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useConfig } from 'wagmi';
import { markNotifRead } from '../leagues/useNotifSocketUpdates';
const formatError = (error: string) => {
  const insufficientError = error.includes('exceeds the balance of the account');
  if (insufficientError) {
    return 'Insufficient gas';
  }
  return error;
};
const Notification = ({
  notifId,
  heading,
  description,
  icon,
  leagueNotifId
}: {
  notifId: number | string;
  heading: string;
  description: string;
  icon: ReactNode;
  leagueNotifId?: string;
}) => {
  useEffect(() => {
    if (leagueNotifId) {
      markNotifRead({
        notifId: leagueNotifId
      });
    }
  }, []);
  return <div className="flex gap-5 py-4 px-4 relative rounded-lg bg-grey-800 border-grey-550 border" data-sentry-component="Notification" data-sentry-source-file="useAppToast.tsx">
      {icon}
      <div className="flex flex-col gap-1">
        <p className="text-lg font-medium text-grey-000">{heading}</p>
        <p className="text-sm font-normal text-grey-100">{description}</p>
      </div>

      <button className="absolute right-3 top-3 opacity-70 transition-opacity hover:opacity-100 focus:outline-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground bg-grey-500 border-grey-400 border rounded-full p-1 h-7 w-7 flex items-center justify-center" onClick={() => {
      toast.dismiss(notifId);
    }}>
        <X className="h-4 w-4" strokeWidth={4} data-sentry-element="X" data-sentry-source-file="useAppToast.tsx" />
      </button>
    </div>;
};
const ActionNotification = ({
  notifId,
  heading,
  description,
  icon,
  action
}: {
  notifId: string | number;
  heading: ReactNode;
  description: ReactNode;
  icon: ReactNode;
  action: ReactNode;
}) => {
  return <div className="flex gap-5 p-4 relative rounded-lg bg-grey-800 border border-grey-550" data-sentry-component="ActionNotification" data-sentry-source-file="useAppToast.tsx">
      {icon}
      <div className="flex flex-col gap-1">
        {heading}
        {description}
        {action}
      </div>

      <button className="absolute right-3 top-3 opacity-70 transition-opacity hover:opacity-100 focus:outline-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground bg-grey-500 border-grey-400 border rounded-full p-1 h-7 w-7 flex items-center justify-center" onClick={() => {
      toast.dismiss(notifId);
    }}>
        <X className="h-4 w-4" strokeWidth={4} data-sentry-element="X" data-sentry-source-file="useAppToast.tsx" />
      </button>
    </div>;
};
const useAppToast = () => {
  const {
    chains
  } = useConfig();
  const {
    updateUserStore
  } = useUserStore();
  const getTxUrl = useCallback((hash: string) => `${chains[0].blockExplorers.default.url}/tx/${hash}`, [chains]);
  const toasts = useMemo(() => ({
    txnInitiate: () => {
      toast.custom(t => {
        return <Notification notifId={t} heading="Please confirm in your wallet" description="Please wait for a few moments...." icon={<>
                  <Image src="/images/toast/blocks-processing.svg" width={60} height={60} alt="toast" />
                </>} />;
      });
    },
    txnSuccess: (hash: string) => {
      toast.custom(t => {
        return <ActionNotification notifId={t} heading={<p className="text-lg font-medium text-grey-000">Transaction successful.</p>} description="" action={<div role="button" onClick={() => window.open(getTxUrl(hash), '_blank')} className="flex gap-2 items-center">
                  <p className="text-sm font-normal underline underline-offset-2 text-primary-200">
                    View on Etherscan
                  </p>
                  <SquareArrowOutUpRight size={16} color="#96e21b" />
                </div>} icon={<>
                  <Image src="/images/toast/blocks-success.svg" width={60} height={60} alt="toast" />
                </>} />;
      });
    },
    processing: () => toast.custom(t => {
      return <Notification notifId={t} icon={<>
                  <Image src="/images/toast/blocks-processing.svg" width={60} height={60} alt="toast" />
                </>} heading="Almost there!" description="Waiting for transaction confirmation..." />;
    }),
    failed: ({
      error
    }: {
      error: string;
    }) => {
      const err = formatError(error);
      const isInsufficentGas = err === 'Insufficient gas';
      toast.custom(t => {
        return <Notification notifId={t} icon={<>
                  <Image src="/images/toast/blocks-fail.svg" width={60} height={60} alt="toast" />
                </>} heading={isInsufficentGas ? err : 'Something went wrong!'} description={isInsufficentGas ? '' : err} />;
      });
    },
    txnFailed: (error: string) => toast.custom(t => {
      return <Notification notifId={t} icon={<>
                  <Image src="/images/toast/blocks-fail.svg" width={60} height={60} alt="toast" />
                </>} heading="Transaction failed!" description={formatError(error)} />;
    }),
    success: ({
      message,
      heading
    }: {
      message: string;
      heading: string;
    }) => toast.custom(t => {
      return <Notification notifId={t} icon={<>
                  <CheckCircleIcon width={60} height={60} color="green" />
                </>} heading={heading} description={message} />;
    }),
    copied: (message: string) => toast.custom(t => {
      return <Notification notifId={t} icon={<>
                  <CheckCircleIcon width={60} height={60} className="text-primary-200" />
                </>} heading="Copied to Clipboard" description={message} />;
    }),
    privateKeyCopied: () => toast.custom(t => {
      return <Notification notifId={t} icon={<>
                  <CheckCircleIcon width={60} height={60} className="text-primary-200" />
                </>} heading="Private Key Copied to Clipboard" description="Never share your private key with anyone!" />;
    }),
    oneCTNudge: () => toast.custom(t => {
      return <ActionNotification notifId={t} icon={<>
                  <Image src="/images/1ct/toast-image.svg" width={60} height={60} alt="toast" />
                </>} heading={<p className="text-lg font-medium text-grey-000">One-Click Trading available</p>} description={<div className="flex flex-col max-w-[250px] md:max-w-[400px]">
                  <p className="text-wrap text-sm font-normal">{`We noticed you don’t have 1CT enabled`}</p>
                  <p className="text-wrap text-sm font-normal">
                    With 1CT, you can place a trade without signing transactions.
                  </p>
                </div>} action={<p className="text-sm font-normal text-primary-200 underline underline-offset-2 underline-primary-200 cursor-pointer my-2" onClick={() => {
        updateUserStore('openOnboard1CT', true);
      }}>
                  Enable 1CT
                </p>} />;
    }),
    leagueDemotion: ({
      league,
      notifId
    }: {
      league: string;
      notifId: string;
    }) => toast.custom(t => {
      return <Notification notifId={t} icon={<Image src="/images/leagues/notifications/demoted.svg" height={60} width={60} alt="promoted" />} heading={`You’ve been demoted to ${league}.`} description="Keep trading to climb your way back up." leagueNotifId={notifId} />;
    }),
    leaguePromotion: ({
      league,
      notifId
    }: {
      league: string;
      notifId: string;
    }) => toast.custom(t => {
      return <Notification notifId={t} icon={<Image src="/images/leagues/notifications/promoted.svg" height={60} width={60} alt="promoted" />} heading={`You’ve been promoted to ${league}.`} description="Great job! You will earn from a bigger prize pool." leagueNotifId={notifId} />;
    }),
    info: (message: string, description?: string) => toast.custom(t => {
      return <Notification icon={<>
                  <CheckCircleIcon width={60} height={60} className="text-primary-200" />
                </>} notifId={t} heading={message} description={description || ''} />;
    }),
    error: (message: string, description?: string) => toast.custom(t => {
      return <Notification icon={<>
                  <XCircle width={60} height={60} className="text-error-100" />
                </>} notifId={t} heading={message} description={description || ''} />;
    })
  }), [toast, getTxUrl]);
  return {
    ...toasts
  };
};
export default useAppToast;