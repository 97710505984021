import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Address } from 'viem';
import { roundDownTrim } from './numbers';
import BigNumber from 'bignumber.js';

export const retryDataUpdate = ({
  func,
  maxRetryCount = 3,
  delay = 0,
  multiple = 3000,
}: {
  func: () => void;
  maxRetryCount?: number;
  delay?: number;
  multiple?: number;
}) => {
  func();
  for (let i = 0; i < maxRetryCount; i++)
    setTimeout(
      () => {
        func();
      },
      delay + multiple * i
    );
};

export const getPrecision = (number: BigNumber, precision: number = 2) => {
  if (number.isNaN()) return 0;
  const expString = number.toExponential();
  const [, exp] = expString.split('e');
  const requiredPrecision = Math.max(precision, precision - Number(exp));
  return requiredPrecision;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getCurrentTimestamp = () => Math.trunc(Date.now() / 1000);
export function currencyIcons(currency: string) {
  return {
    ['EUR-USD']: 'eur-usd',
    ['GBP-USD']: 'gbp-usd',
    ['USD-JPY']: 'usd-jpy',
    ['USD-CAD']: 'usd-cad',
    ['USD-CHF']: 'usd-chf',
    ['USD-SEK']: 'usd-sek',
    ['AUD-USD']: 'aud-usd',
    ['NZD-USD']: 'nzd-usd',
    ['USD-SGD']: 'usd-sgd',
    ['USD-TRY']: 'usd-try',
  }[currency];
}

export const numberWithCommas = function (x: number, decimals = 2) {
  const bn = BigNumber.clone({
    DECIMAL_PLACES: decimals,
    FORMAT: {
      groupSeparator: ',',
      groupSize: 3,
      decimalSeparator: '.',
    },
  });

  return bn(x).toFormat();
};

export function formatNumberWithDecimal(number = 0, dp = 2) {
  const formattedNumber = number.toLocaleString('en-US', {
    maximumFractionDigits: dp,
  });
  const decimalPart = formattedNumber.includes('.') ? `.${formattedNumber.split('.')[1]}` : '.00';
  const formattedWithoutDecimal = formattedNumber.split('.')[0];

  return {
    formattedNumber: formattedWithoutDecimal,
    decimalPart: decimalPart,
  };
}

export function formatNumber(number = 0, dp = 2) {
  const formattedNumber = number.toLocaleString('en-US', {
    maximumFractionDigits: dp,
  });
  return formattedNumber;
}

export function nFormatter(num: number, digits = 1, roundFixed = false) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  let item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });

  if (!item && num > 0) {
    item = lookup[0];
  }

  const roundFn = roundFixed
    ? (x: number, digits: number) => Number(x).toFixed(digits)
    : roundDownTrim;

  return item ? roundFn(num / item.value, digits) + item.symbol : roundFn(num, digits);
}

export function truncate(string: string, length: number) {
  return string?.length > length ? string.substring(0, length) + '...' : string;
}

export function reverseTruncate(string: string, length: number) {
  return string?.length > length ? string.substring(string.length - length) : string;
}

export function formatAddress(address: Address, length = 4) {
  return truncate(address, length) + reverseTruncate(address, length);
}

export const nFormatUsd = function (x: number) {
  let number = nFormatter(Math.abs(x)).toString();
  if (x < 0) {
    number = '-$' + number.replace('-', '');
  } else {
    number = '$' + number;
  }
  return number;
};

export const formatUsd = function (x: number) {
  let number = numberWithCommas(x);
  if (number?.includes('-')) {
    number = '-$' + number.replace('-', '');
  } else {
    number = '$' + number;
  }
  return number;
};
